import {makeAutoObservable, set} from "mobx";
import {camelizeKeys, snakelizeKeys} from "../../../utils/renameKeys";
import {pickCls} from "../../../utils/immutableLodash";

export class UserFilterSetModel {
    id = 0;
    emailSet = [];
    name = "";

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const reply = pickCls(
            this,
            camelizeKeys(data)
        );
        set(this, reply);
    }
}

export class UserFilterSetDAOModel {
    name = "";
    emailSet = [];
    bizGroup = 0;

    constructor(data) {
        const classroom = pickCls(this, data);
        Object.assign(this, snakelizeKeys(classroom));
    }
}