export const searchParamsToObject = (searchParams) => Object.fromEntries(new URLSearchParams(searchParams));

export const loginPageUrl = ({ nextUrl }) => {
    if(!nextUrl) {
        return "/accounts/login";
    }

    const encodeUrl = encodeURIComponent(nextUrl);

    return `/accounts/login?nextUrl=${encodeUrl}`;
};

export const getLoginPageUrlFromThisLocation = () => {
    const nextUrl = window.location.pathname + window.location.search;
    return loginPageUrl({nextUrl});
}