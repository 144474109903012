import React from "react";
import {CardActionArea, CardMedia} from "@mui/material";
import {useNavigate} from "react-router-dom";

export const LogoButton = ({sx, }) => {
    const navigate = useNavigate();

    const redirectToMainPage = () => {
        navigate('/');
    }

    return (
        <CardActionArea
            onClick={redirectToMainPage}
            sx={{
                width: 130,
                ...sx,
            }}
        >
            <CardMedia
                height={32}
                component="img"
                image={"https://contents.codetree.ai/homepage/images/logos/Horizontal_logo_black_transparent.png"}
                title="Logo Image"
            />
        </CardActionArea>
    )
}