import React, {useState} from "react";
import {PasswordTemplate} from "./PasswordTemplate";
import { useStores } from "../../common/modules/useStores"
// import {searchParamsToObject} from "../../../../../../utils/url";
import { snackActions } from "../../utils/SnackbarUtilsConfigurator";
import { UpdatePasswordDAOModel } from "../../common/modules/auth/UserModel";
import { errorToast } from "../../utils/errorToast";


export const PasswordContainer = () => {
    const { authStore } = useStores();
    const { updatePassword, logout } = authStore;

    const [loading, setLoading] = useState(false);

    // const {oldPassword} = searchParamsToObject(window.location.search);

    const handleUpdatePassword = (oldPassword, newPassword, retypeNewPassword) => {
        if(newPassword !== retypeNewPassword) {
            snackActions.error("비밀번호와 비밀번호 확인이 같지 않습니다.");
            return;
        }

        if(newPassword.length < 8) {
            snackActions.error("비밀번호는 최소 8자 이상이어야 합니다.");
            return;
        }

        setLoading(true);

        const body = new UpdatePasswordDAOModel({
            oldPassword, newPassword
        });

        updatePassword({body})
            .then(() => {
                snackActions.success("성공적으로 변경되었습니다.");
                logout();
            })
            .catch(e => {
                errorToast(e);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <PasswordTemplate
            handleUpdatePassword={handleUpdatePassword}
            loading={loading}
        />
    )
}
