import {makeAutoObservable, flow} from "mobx";
import { BizGroupModel } from "./BizGroupModel";
import BizGroupRepository from "./BizGroupRepository";

export default class BizGroupStore {
    bizGroups = [];
    currBizGroupId = -1;
    isFetching = true;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            findAll: flow,
        }, { autoBind: true });
    }

    *findAll() {
        this.isFetching = true;
        const response = yield BizGroupRepository.findAll();
        this.bizGroups = response.data.map(data => new BizGroupModel(data))
        this.isFetching = false;
    }

    selectBizGroupId(bizGroupId) {
        this.currBizGroupId = Number(bizGroupId);
    }
}