import { Button, Stack, TextField } from "@mui/material"
import React from "react"

export const LoginTempalte = ({
    handleChange,
    handleSubmit,
    inputs,
}) => {
    return (
        <Stack alignItems={"center"} minHeight={'100vh'} justifyContent={'center'}>
            <Stack component={'form'} onSubmit={handleSubmit} spacing={2} p={3}>
                <TextField
                    required
                    label="아이디"
                    name="username"
                    onChange={handleChange}
                    value={inputs["username"]}
                />
                <TextField
                    required
                    type={"password"}
                    label="비밀번호"
                    name="password"
                    onChange={handleChange}
                    value={inputs["password"]}
                />
                <Button variant="contained" type={"submit"}>
                    로그인
                </Button>
            </Stack>
        </Stack>
    )
}