import { GET, PATCH, POST } from "../../../utils/api";
import { snakelizeKeys } from "../../../utils/renameKeys";
import { objectToQueryString } from "../../../utils/immutableLodash";
import { mockData } from "../../../utils/mock"
import moment from "moment";

class BizGroupRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll() {
        return GET(`biz/biz-groups/`)
    }
    
}

export default new BizGroupRepository();
