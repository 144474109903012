import {snackActions} from "./SnackbarUtilsConfigurator";

export const errorToast = (e) => {
    if (
        e.response &&
        (e.response.status === 400 ||
            e.response.status === 403 ||
            e.response.status === 500)
    )
        snackActions.error(JSON.stringify(e.response.data).slice(0, 100));
    else snackActions.error(e.message);
}