import React from "react";
import { Stack, TextField, Typography } from "@mui/material";

export const LargeWhiteTextField = ({
    label,
    onChange,
    value,
    placeholder,
    ...props
}) => {
    return (
        <Stack direction={"column"} spacing={1}>
            <Typography
                variant={"body2"}
                children={label}
                color={"common.black"}
            />
            <TextField
                fullWidth
                value={value}
                onChange={onChange}
                sx={{
                    bgcolor: "common.white",
                    color: "#000000",
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                        color: "common.black",
                    },
                    "& .MuiOutlinedInput-input": {
                        fontWeight: 700,
                        fontSize: 20,
                        lineHeight: "120%",
                        p: 2.5,
                    },
                }}
                placeholder={placeholder}
                {...props}
            />
        </Stack>
    );
};
