import { Button, Stack, TextField, Typography } from "@mui/material"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useStores } from "../../common/modules/useStores"

export const Logout = observer(({
}) => {
    const {authStore} = useStores();
    const navigate = useNavigate();

    useEffect(() => {
        authStore.logout()
        setTimeout(() => {navigate('/login')}, 3000)
    }, [])

    return (
        <Stack alignItems={"center"} minHeight={'100vh'} justifyContent={'center'}>
            <Stack spacing={2} p={3}>
                <Typography variant="h5">정상적으로 로그아웃 되었습니다.</Typography>
                <Typography variant="body1">3초뒤에 홈으로 돌아갑니다.</Typography>
            </Stack>
        </Stack>
    )
}) 