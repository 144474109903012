import {camelCase, snakeCase} from "lodash";
import { toJS } from "mobx";

/* eslint-disable */
export const camelizeKeys = (obj) => {
    if(Array.isArray(obj)) {
        return obj.map(v => camelizeKeys(v));
    }

    if (obj && obj.constructor === Object) {
        return Object.keys(obj).reduce((result, key) => ({
            ...result,
            [camelCase(key)] : camelizeKeys(obj[key]),
        }), {})
    }

    return obj;
}

export const snakelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map((v) => snakelizeKeys(v));
    }
    if (obj && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [snakeCase(key)]: snakelizeKeys(obj[key]),
            }),
            {}
        );
    }
    return obj;
};

const clone = (obj) => {
    if (obj === null || typeof obj !== "object") return obj;

    const copy = obj.constructor();

    for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
            copy[attr] = obj[attr];
        }
    }

    return copy;
};

export const renameKeys = (object, changes) => {
    object = toJS(object); // avoid using mobx object when rename keys
    if (!changes || typeof changes !== "object") {
        return object;
    }

    if (Array.isArray(object)) {
        const newArray = [];
        object.forEach((_, i) => {
            newArray.push(renameKeys(object[i], changes));
        });
        return newArray;
    }
    if (typeof object !== "object") {
        return object;
    }
    const copy = clone(object);

    for (const key in changes) {
        if (typeof changes[key] === "object") {
            if (copy.hasOwnProperty(key)) {
                copy[key] = renameKeys(copy[key], changes[key]);
                continue;
            }
        }

        if (copy.hasOwnProperty(key)) {
            const temp = copy[key];

            copy[changes[key]] = temp;
            delete copy[key];
        }
    }
    return copy;
};
