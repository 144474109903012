import Cookies from 'js-cookie'

const CT_TOKEN_KEY = "CODETREE_TOKEN"
const CT_REFRESH_TOKEN_KEY = "CODETREE_REFRESH_TOKEN"

export const getCookie = (key) => {
    return Cookies.get(key);
}

export const removeCookie = (key) => {
    return Cookies.remove(key);
}

export const setCookie = (key, value, config) => {
    return Cookies.set(key, value, config);
}

export const getToken = () => {
    return getCookie(CT_TOKEN_KEY);
}

export const removeToken = () => {
    return removeCookie(CT_TOKEN_KEY);
}

export const setToken = (value) => {
    return setCookie(CT_TOKEN_KEY, value, { expires: new Date(2038, 1, 1)})
}

export const getRefreshToken = () => {
    return getCookie(CT_REFRESH_TOKEN_KEY);
}

export const removeRefreshToken = () => {
    return removeCookie(CT_REFRESH_TOKEN_KEY);
}

export const setRefreshToken = (value) => {
    return setCookie(CT_REFRESH_TOKEN_KEY, value, { expires: new Date(2038, 1, 1)})
}
