import React, { useEffect } from "react"
import { BrowserRouter, Navigate, Route, Router, Routes, useLocation, useParams, Outlet } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./utils/SnackbarUtilsConfigurator";
import ChannelService from "./common/components/channel/ChannelService"
import { MainPage } from "./pages/MainPage";



function App() {

	useEffect(() => {
        ChannelService.boot({
            pluginKey: "a9562cfd-5bfb-4a7d-945f-b2e857e3146c", // please fill with your plugin key
        });

        return () => ChannelService.shutdown();
    }, [])

	return (
		<SnackbarProvider
			maxSnack={3}
			autoHideDuration={4000}
		>
			<SnackbarUtilsConfigurator />
			<BrowserRouter>
				<Routes>
					<Route path="/*" element={<MainPage/>}/>
				</Routes>
			</BrowserRouter>
		</SnackbarProvider>

	);
}


export default App;
