import { Button, Stack, TextField, Typography } from "@mui/material"
import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import { setIn } from "../../utils/immutableLodash"

export const PasswordTemplate = observer(({
    handleUpdatePassword,
}) => {

    const [inputs, setInputs] = useState({
        oldPassword: "",
        newPassword: "",
        retypeNewPassword: "",
    })

    const {oldPassword, newPassword, retypeNewPassword} = inputs;

    const TEXT_FIELD_ITEMS = [
        {name: "oldPassword", label: "현재 비밀번호", value: oldPassword,},
        {name: "newPassword", label: "새 비밀번호", value: newPassword,},
        {name: "retypeNewPassword", label: "새 비밀번호 확인", value: retypeNewPassword },
    ]

    const handleChange = (e) => {
        const {name, value} = e.target;

        setInputs(setIn(name, value, inputs));
    }

    return (
        <Stack alignItems={"center"} minHeight={'100vh'} justifyContent={'center'}>
            <Stack component={'form'} spacing={2} p={3}>
                <Typography variant="h5" fontWeight={600} gutterBottom>비밀번호 변경</Typography>
                {TEXT_FIELD_ITEMS.map(({name, label, value}) => (
                    <TextField
                        required
                        type={"password"}
                        label={label}
                        name={name}
                        onChange={handleChange}
                        value={value}
                    />
                ))}
                <Button variant="contained" onClick={() => handleUpdatePassword(oldPassword, newPassword, retypeNewPassword)}>
                    변경하기
                </Button>
            </Stack>
        </Stack>
    )
})