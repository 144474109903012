import { GET, PATCH, POST, DELETE } from "../../../utils/api";
import { snakelizeKeys } from "../../../utils/renameKeys";
import { objectToQueryString } from "../../../utils/immutableLodash";
import { mockData } from "../../../utils/mock"
import moment from "moment";

class MemberRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({bizGroupId, userFilterSetId}) {
        if(userFilterSetId)
            return GET(`biz/biz-groups/${bizGroupId}/latest-site-license/group-users/?user_filter_set_id=${userFilterSetId}`)
        else
            return GET(`biz/biz-groups/${bizGroupId}/latest-site-license/group-users/`)
    }

    add({bizGroupId, userFilterSetId, body}) {
        if(userFilterSetId)
            return POST(`biz/biz-groups/${bizGroupId}/latest-site-license/group-users/?user_filter_set_id=${userFilterSetId}`, body)
        else
            return POST(`biz/biz-groups/${bizGroupId}/latest-site-license/group-users/`, body)
    }

    delete({bizGroupId, userFilterSetId, body}) {
        if(userFilterSetId)
            return DELETE(`biz/biz-groups/${bizGroupId}/latest-site-license/group-users/?user_filter_set_id=${userFilterSetId}`, body)
        else
            return DELETE(`biz/biz-groups/${bizGroupId}/latest-site-license/group-users/`, body)
    }

    
}

export default new MemberRepository();
