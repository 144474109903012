import {makeAutoObservable, set} from "mobx";
import {snakelizeKeys} from "../../../utils/renameKeys";
import {camelizeKeys} from "../../../utils/renameKeys";
import {pickCls} from "../../../utils/immutableLodash";
import moment from "moment";

export class BizGroupModel {
    
    id = 0;

    name = "";

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const reply = pickCls(
            this,
            camelizeKeys(data)
        );
        set(this, reply);
    }
}

