import {GET, PATCH, PUT, unauthorizedPOST} from "../../../utils/api";

class AuthRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    login({ body }) {
        return unauthorizedPOST("api-token-auth/", body);
    }

    verifyToken() {
        return GET("api-token-verify/");
    }

    updateProfile({profileId, body}) {
        return PATCH(`user-profiles/${profileId}/`, body);
    }

    getSocialUser({ body }) {
        return unauthorizedPOST("social-login/", body);
    }

    connectSocialUser({ body, id }) {
        return unauthorizedPOST(`api-token-auth/?id=${id}`, body)
    }

    activate({uid, phoneNumber}) {
        return unauthorizedPOST(`activate/${uid}/`, {"phone_number": phoneNumber});
    }

    resetPassword({phoneNumber}) {
        return unauthorizedPOST(`reset-password/`, {"phone_number": phoneNumber});
    }

    updateEmail({ body }) {
        return PATCH("update-email/", body);
    }

    updatePhoneNumber({ body }) {
        return PATCH("update-phone-number/", body);
    }

    updatePassword({ body }) {
        return PUT(`update-password/`, body);
    }
}

export default new AuthRepository();
