import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    Stack,
    darken,
    alpha,
} from "@mui/material";
import {observer} from "mobx-react-lite";
import {LargeWhiteTextField} from "../../../common/components/textField/LargeWhiteTextField";
import {UserFilterSetDAOModel} from "../../../common/modules/siteLicense/UserFilterSetModel";
import {useStores} from "../../../common/modules/useStores";
import {snackActions} from "../../../utils/SnackbarUtilsConfigurator";
import {errorToast} from "../../../utils/errorToast";
import { setIn } from "../../../utils/immutableLodash"

const FilterSetForm = observer(({ inputs, handleChange }) => {
    const { name } = inputs;

    return (
        <Stack
            direction="column"
            spacing={2.5}
            backgroundColor="#F5F5F7"
            p={2.5}
            width="100%"
        >
            <LargeWhiteTextField
                label="학급 이름"
                onChange={(e) => {
                    handleChange("name", e.target.value);
                }}
                value={name}
                placeholder="2023년 1학기 컴퓨터 프로그래밍"
            />
        </Stack>
    );
});

export const FilterSetAddDialog = ({ bizGroupId, open, onClose }) => {
    const { siteLicenseStore } = useStores();

    const [inputs, setInputs] = useState({
        name: "",
        emailSet: [],
        bizGroup: bizGroupId
    });

    const handleChange = (name, value) => {
        setInputs(setIn(name, value, inputs));
    };

    const handleSubmit = (_inputs) => {
        const body = new UserFilterSetDAOModel(_inputs);
        siteLicenseStore
            .addUserFilterSet({ bizGroupId, body })
            .then(() => {
                snackActions.success("성공적으로 등록되었습니다.");
            })
            .catch((e) => {
                errorToast(e);
            })
            .finally(() => {
                onClose();
            });
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    bgcolor: "common.white",
                    borderRadius: 3,
                    width: 551,
                },
            }}
        >
            <FilterSetForm inputs={inputs} handleChange={handleChange} />
            <DialogActions>
                <Stack direction="row" spacing={1.25}>
                    <Button
                        sx={{
                            bgcolor: "common.white",
                            color: "#808080",
                            px: 2.25,
                            py: 1.5,
                            borderRadius: 1.5,
                            border: "1px solid #B3B3B3",
                        }}
                        children="취소"
                        onClick={onClose}
                    />
                    <Button
                        sx={{
                            bgcolor: "#FF5555",
                            color: "#FFFFFF",
                            px: 2.25,
                            py: 1.5,
                            borderRadius: 1.5,
                            "&:hover": {
                                bgcolor: darken("#FF5555", 0.1),
                            },
                            "&.Mui-disabled": {
                                bgcolor: alpha("#FF5555", 0.5),
                                color: "#FFFFFF",
                            },
                        }}
                        children="확인"
                        onClick={() => handleSubmit(inputs)}
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
