import {makeAutoObservable, flow} from "mobx";
import { SiteLicenseModel } from "./SiteLicenseModel";
import SiteLicenseRepository from "./SiteLicenseRepository";
import moment from "moment";
import {UserFilterSetModel} from "./UserFilterSetModel";
import {errorToast} from "../../../utils/errorToast";

export default class SiteLicenseStore {
    licenses = [];
    latestLicense = null
    isFetching = true;

    userFilterSets = [];
    selectedFilterSet = undefined;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            findAll: flow,
            findUserFilterSetAll: flow,
            addUserFilterSet: flow,
            deleteUserFilterSet: flow,
        }, { autoBind: true });
    }

    *findAll({bizGroupId}) {
        this.isFetching = true;
        const response = yield SiteLicenseRepository.findAll({bizGroupId});
        this.isFetching = false;
        this.licenses = response.data.map(data => new SiteLicenseModel(data))
        this.latestLicense = [...this.licenses].sort((a, b) => moment(b.finishedDate).diff(moment(a.finishedDate)))[0]
    }

    setFilterSet(userFilterSet) {
        this.selectedFilterSet = userFilterSet;
    }

    *findUserFilterSetAll({bizGroupId}) {
        const response = yield SiteLicenseRepository.findUserFilterSetAll({bizGroupId});
        this.userFilterSets = response.data.map(data => new UserFilterSetModel(data))
    }

    *addUserFilterSet({ bizGroupId, body }) {
        const response = yield SiteLicenseRepository.addUserFilterSet({ bizGroupId, body });
        const newAddUserFilterSet = new UserFilterSetModel(response.data);
        this.userFilterSets.replace([newAddUserFilterSet, ...this.userFilterSets]);
        this.selectedFilterSet = newAddUserFilterSet;
        this.rootStore.memberStore.findAll({bizGroupId: bizGroupId, userFilterSetId: newAddUserFilterSet.id});
    }

    *deleteUserFilterSet({ bizGroupId, userFilterSetId }) {
        yield SiteLicenseRepository.deleteUserFilterSet({ bizGroupId, userFilterSetId });
        const _this = this;
        this.findUserFilterSetAll({ bizGroupId }).then(() => {
            _this.selectedFilterSet = undefined;
            _this.rootStore.memberStore.findAll({bizGroupId: bizGroupId});
        })
        .catch((e) => {
            errorToast(e);
        })
    }
}