import {DELETE, GET, POST} from "../../../utils/api";
class SiteLicenseRepository {
    constructor(attr) {
        Object.assign(this, attr);
    }

    findAll({bizGroupId}) {
        return GET(`biz/biz-groups/${bizGroupId}/biz-site-licenses/`)
    }

    findUserFilterSetAll({bizGroupId}) {
        return GET(`biz/biz-groups/${bizGroupId}/biz-user-filter-sets/`)
    }

    addUserFilterSet({ bizGroupId, body }) {
        return POST(`biz/biz-groups/${bizGroupId}/biz-user-filter-sets/`, body);
    }

    deleteUserFilterSet({ bizGroupId, userFilterSetId}) {
        return DELETE(`biz/biz-groups/${bizGroupId}/biz-user-filter-sets/${userFilterSetId}/`);
    }
}

export default new SiteLicenseRepository();
