import { Paper, Typography, TextField, Stack, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Button } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "../../../common/modules/useStores";
import { observer } from "mobx-react-lite";
import { GET } from "../../../utils/api";
import { camelizeKeys } from "../../../utils/renameKeys";
import * as XLSX from 'xlsx';
import { errorToast } from "../../../utils/errorToast";

const headCells = [
    {
        id: 'kst_time',
        label: '응시일시',
    },
    {
        id: 'name',
        label: '이름',
    },
    {
        id: 'student_email',
        label: '이메일',
    },
    {
        id: 'student_id',
        label: '학번',
    },
    {
        id: 'score',
        label: '점수',
    },
];

export const LevelTestContainer = observer(() => {
    const [start, setStart] = useState(moment());
    const [end, setEnd] = useState(moment());
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const { bizGroupId:currBizGroupId } = useParams();

    const { siteLicenseStore } = useStores();
    const { latestLicense } = siteLicenseStore;

    const handleExcelDownload = () => {
        const headers = headCells.map(headCell => headCell.label);
        const dataset = rows.map(row => [
            row.createdAt,
            row.name,
            row.studentEmail,
            row.studentId,
            row.score,
        ]);

        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...dataset]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        const excelFile = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });

        const blob = new Blob([excelFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '학습 현황.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const getReports = () => {
        setLoading(true);
        GET(`biz/biz-groups/${currBizGroupId}/level-test-reports/?start=${start.format('YYYY-MM-DD')}&end=${end.clone().add(1, 'd').format('YYYY-MM-DD')}`).then(res => {
            setRows(camelizeKeys(res.data));
        }).catch(err => {
            errorToast(err);
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        if(currBizGroupId !== -1) {
            siteLicenseStore.findAll({bizGroupId: currBizGroupId});
        }
    }, [currBizGroupId])

    useEffect(() => {
        if(latestLicense) {
            getReports();
        }
    }, [latestLicense, start, end])

    if (siteLicenseStore.isFetching) {
        return (
            <Stack alignItems={"center"} minHeight={'100vh'} justifyContent={'flex-start'} p={4} bgcolor={'#F3F6F9'}>
                <CircularProgress/>
            </Stack>
        )
    }

    return (latestLicense && latestLicense.isSwEvalContracted? (
        <Paper sx={{ width: '100%', mb: 2 }}>
            <Typography variant="h6" component="div" sx={{ p: 2 }}>
                역량평가 현황
            </Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Stack
                    direction="row"
                    spacing={2}
                    px={2}
                    justifyContent={'space-between'}
                >
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <DatePicker
                            label="start"
                            value={start}
                            onChange={(newValue) => {
                                if(!newValue || !newValue.isValid()) return;
                                setStart(newValue);
                            }}
                            disableFuture
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DatePicker 
                            label="end"
                            value={end}
                            onChange={(newValue) => {
                                if(!newValue || !newValue.isValid()) return;
                                setEnd(newValue);
                            }}
                            disableFuture
                        />
                    </Stack>
                    <Button
                        variant='contained'
                        onClick={handleExcelDownload}
                        startIcon={<AddCircleIcon />}
                        flexShrink={0}
                        disabled={loading}
                    >
                        엑셀 다운로드
                    </Button>
                </Stack>
            </LocalizationProvider>
            {
                loading ? (
                    <Stack alignItems={"center"} justifyContent={'flex-start'} p={4}>
                        <CircularProgress/>
                    </Stack>
                ) : (
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                        >
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={'left'}
                                        >
                                            {headCell.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.createdAt}
                                        >
                                            <TableCell>{row.createdAt}</TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{row.studentEmail}</TableCell>
                                            <TableCell>{row.studentId}</TableCell>
                                            <TableCell>{row.score}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }
        </Paper>
    ) : (
        <Typography>{latestLicense && !latestLicense.isSwEvalContracted ? '해당 기능의 권한이 없습니다. 사용을 원하신다면 담당자에게 연락해주시기 바랍니다.' : '라이센스 계약이 필요합니다.'}</Typography>
    ))
})