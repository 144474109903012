import {makeAutoObservable, set} from "mobx";
import {snakelizeKeys} from "../../../utils/renameKeys";
import {camelizeKeys} from "../../../utils/renameKeys";
import {pickCls} from "../../../utils/immutableLodash";
import moment from "moment";

export class SiteLicenseModel {
    id = 0;

    name = "";

    maxUsersCnt = 0;

    startedDate = moment();

    finishedDate = moment();

    fullContract = false;

    domain = "";

    isSwEvalContracted = false;

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const reply = pickCls(
            this,
            camelizeKeys(data)
        );
        set(this, reply);
    }
}

