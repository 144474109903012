import React from 'react'
import {Navigate, Route, Routes} from "react-router-dom";
import { CollegeContainer } from './CollegeContainer';

export const CollegePage = () => {
    return (
        <Routes>
            {/* <Route index element={<Navigate to={``} replace/>}/> */}
            <Route path="/*" element={<CollegeContainer/>}/>
        </Routes>
    )
}