import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { CircularProgress } from '@mui/material'
import { observer } from 'mobx-react-lite';
import { useStores } from '../common/modules/useStores';


export const LicenseSelect = observer(({
  loading, bizGroups, currBizGroupId, handleSelect
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, option) => {
    handleSelect(option.id)
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>{
      loading ? <CircularProgress /> :
        <>
          <List
            component="nav"
            aria-label="Device settings"
            sx={{ bgcolor: 'background.paper' }}
          >
            <ListItem
              button
              id="lock-button"
              aria-haspopup="listbox"
              aria-controls="lock-menu"
              aria-label="when device is locked"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClickListItem}
            >
              <ListItemText
                primary={bizGroups.length === 0 || currBizGroupId === -1 || currBizGroupId === undefined ? `학교를 선택해주세요.` : bizGroups.find((option) => option.id === Number(currBizGroupId)).name}
              />
            </ListItem>
          </List>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox',
            }}
          >
            {bizGroups.map((option) => (
              <MenuItem
                key={option.id}
                selected={option.id === currBizGroupId}
                onClick={(event) => handleMenuItemClick(event, option)}
              >
                {option.name}
              </MenuItem>
            ))}
          </Menu>

        </>
    }</>
  );
}) 