import React, { useEffect, useState } from "react"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, TextField, Typography, Box, IconButton, Table, TableHead, TableCell, TableRow, TableBody, Paper, Input } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons/faFileCsv';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

export const DeleteMemberDialog = ({ 
    open, 
    handleClose,
    handleConfirm,
}) => {

    const [alertOpen, setAlertOpen] = useState(false)

    const onClose = () => {
        handleClose();
    }

    const onConfirm = () => {
        handleConfirm()
        handleClose();
    }


    return (
        <>
            
            <Dialog
                open={open}
                onClose={onClose}
            >
                <DialogTitle>
                    정말 제외하시겠어요?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        진행할 경우 선택한 학생들은 사이트 라이센스 적용 대상에서 제외됩니다.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onClose}>되돌아가기</Button>
                    <Button variant="contained" color="error" onClick={onConfirm}>삭제하기</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}