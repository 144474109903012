import { makeAutoObservable, flow } from "mobx";
import { errorToast } from "../../../utils/errorToast";
import { snakelizeKeys } from "../../../utils/renameKeys";
import { snackActions } from "../../../utils/SnackbarUtilsConfigurator";
import { MemberModel } from "./MemberModel";
import MemberRepository from "./MemberRepository";

export default class MemeberStore {
    members = [];
    isFetching = true;

    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            findAll: flow,
            addMembers: flow,
            deleteMembers: flow,
        }, { autoBind: true });
    }

    *findAll({ bizGroupId, userFilterSetId }) {
        this.isFetching = true;
        const response = yield MemberRepository.findAll({ bizGroupId, userFilterSetId });
        this.members = response.data.map(data => new MemberModel(data))
        this.isFetching = false;
    }

    *addMembers({bizGroupId, userFilterSetId, members:newMembers}) {
        const body = snakelizeKeys({
            emailSet: newMembers.map(({ studentEmail }) =>
                studentEmail
            )
        });
        try {
            this.isFetching = true;
            const response = yield MemberRepository.add({ bizGroupId, userFilterSetId, body });
            this.isFetching = false;
            const req_cnt = newMembers.length
            const dup_cnt = req_cnt + this.members.length - response.data.length
            snackActions.success(`${response.data.length - this.members.length}명이 새롭게 등록되었습니다. ${dup_cnt !== 0 ? `(중복 ${dup_cnt}명)` : ``}`)
            this.members = response.data.map(data => new MemberModel(data));
        } catch (e) {
            errorToast(e)
            this.isFetching = false;
        }
    }

    *deleteMembers({bizGroupId, userFilterSetId, members:deleteMembers}) {
        const body = snakelizeKeys({
            emailSet: deleteMembers.map(({ studentEmail }) =>
                studentEmail
            )
        });

        try {
            this.isFetching = true;
            const response = yield MemberRepository.delete({ bizGroupId, userFilterSetId, body });
            this.isFetching = false;
            const deleted_cnt = this.members.length - response.data.length
            snackActions.success(`${deleted_cnt}명이 명단에서 제외되었습니다.`)
            this.members = response.data.map(data => new MemberModel(data));
        } catch (e) {
            errorToast(e)
            this.isFetching = false;
        }
    }
}