import AuthStore from "./auth/AuthStore";
import MemberStore from "./memeber/MemberStore";
import SiteLicenseStore from "./siteLicense/SiteLicenseStore";
import BizGroupStore from "./bizGroup/BizGroupStore";

export class RootStore {
    constructor() {
        this.authStore = new AuthStore(this);
        this.memberStore = new MemberStore(this);
        this.siteLicenseStore = new SiteLicenseStore(this);
        this.bizGroupStore = new BizGroupStore(this);
    }
}