import {makeAutoObservable, set} from "mobx";
import {pickCls} from "../../../utils/immutableLodash";
import {camelizeKeys, snakelizeKeys} from "../../../utils/renameKeys";

export const MotherTongueType = {
    KOR: "ko-kr",
    ENG: "en-us",
};

export const MotherTongueLabel = {
    [MotherTongueType.KOR]: "한국어",
    [MotherTongueType.ENG]: "English",
};

export const MotherTongueIcon = {
    [MotherTongueType.KOR]:
        "https://contents.codetree.ai/homepage/images/icons/flags/kr.svg",
    [MotherTongueType.ENG]:
        "https://contents.codetree.ai/homepage/images/icons/flags/us.svg",
};

export const AuthProviderType = {
    NAVER: "naver",
    KAKAO: "kakao",
};

export const AuthProviderIcon = {
    [AuthProviderType.NAVER]:
        "https://contents.codetree.ai/homepage/images/main_page/login/naver_button.png",
    [AuthProviderType.KAKAO]:
        "https://contents.codetree.ai/homepage/images/main_page/login/kakao_button.svg",
};

export const AuthProviderLabel = {
    [AuthProviderType.NAVER]: "네이버",
    [AuthProviderType.KAKAO]: "카카오",
};

class PaidModel {

    expiredDate = "";

    authorization = "";

    remainDuration = 0;

    startDate = "";

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const paid = pickCls(
            this,
            camelizeKeys(data)
        );
        set(this, paid);
    }
}

export class UserModel {
    id = 0;

    username = "";

    name = "";

    motherTongue = "";

    profileId = 0;

    profileImage = "";

    phoneNumber = "";

    email = "";

    paid = undefined;

    freeTrialExpiredDate = "";

    bizClasses = [];

    reducedPrice = 0;

    mockTestAuthorization = "";

    isAbTest = false;

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const user = pickCls(
            this,
            camelizeKeys(data)
        );
        user.paid = user.paid && new PaidModel(user.paid);
        set(this, user);
    }

    updateProfile(data) {
        const user = pickCls(
            this,
            camelizeKeys(data)
        )
        set(this, user);
    }
}

export class UserProfileDAOModel {
    constructor(data) {
        Object.assign(this, snakelizeKeys(data));
    }
}

export class SocialUserModel {
    id = 0;

    email = "";

    birthDate = "";

    phoneNumber = "";

    token = "";

    accessToken = "";

    isFirst = false;

    uid = "";

    constructor(data) {
        makeAutoObservable(this, {}, {autoBind: true});
        const socialUser = pickCls(
            this,
            camelizeKeys(data)
        );
        set(this, socialUser);
    }
}

export class SocialUserDAOModel {
    code = "";

    redirectUri = "";

    authProvider = "";

    state = "";

    constructor(data) {
        const user = pickCls(
            this,
            data,
        );
        Object.assign(this, snakelizeKeys(user));
    }
}

export class UpdatePasswordDAOModel {
    oldPassword = "";

    newPassword = "";

    constructor(data) {
        const password = pickCls(
            this,
            data,
        );
        Object.assign(this, snakelizeKeys(password));
    }
}
